<div class="logged-container">
    <div class="column">
      <div class="header col-lg-12 col-md-12 col-sm-12" style="z-index: 99999;">
  
        <div class="left-header col-lg-2 col-md-2 col-sm-2">
          <div>
            <img class="logo" src="assets/logo.png" alt="Logo Pyxie" />
            <span>PYXIE</span>
          </div>
        
        </div>
      </div>
      <div class="body-container">
        <div class="content-container">
            <div style="text-align: center; padding-top: 10rem;">
                <img style="margin-bottom: 2rem; height: 175px;" src="assets/logged-app/img-illustration@2x.png">
                <br>
                <label style="font-size: 20px; color: #F5F5F5;">{{'mosaic.title' | translate}}</label>
        
                <div style="margin-top: 1rem;">
                    <button class="go-button" type="button" (click)="openMosaic()">
                        <span >{{'mosaic.click-here' | translate}}</span>
                    </button>
                </div>
                <br>
                <label style="font-size: 16px; color: #BABABA;">{{'mosaic.download-mosaic' | translate}} <a class="link" href="http://downloads.pyxie.it/index.php/s/38AXvtMOoSR7Hye" target="_blank">{{'mosaic.download-link' | translate}}</a> </label>
                <br>
            </div>
        </div>
  
      </div>
    </div>
</div>