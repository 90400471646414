import { NgModule, LOCALE_ID, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import localeEn from '@angular/common/locales/en';
import localeIt from '@angular/common/locales/it';
import { Auth } from 'aws-amplify';
import { AmplifyModules, AmplifyService } from 'aws-amplify-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { AgmCoreModule } from '@agm/core';
import { ToastrModule } from 'ngx-toastr';
import { AgmMarkerClustererModule } from '@agm/markerclusterer';
import { ChartsModule } from 'ng2-charts';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { FirebaseService } from './core/services/firebase.service';
import { CookieService } from 'ngx-cookie-service';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RedirectMosaicComponent } from './redirect-mosaic/redirect-mosaic.component';
import { NgxStripeModule } from 'ngx-stripe';

// ----------------------------------------------------------------------------

registerLocaleData(localeIt, 'it');
registerLocaleData(localeEn, 'en');

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// ----------------------------------------------------------------------------

@NgModule({
  declarations: [AppComponent, RedirectMosaicComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    NgxSpinnerModule,
    ChartsModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      }
    }),
    AgmCoreModule.forRoot({
      // apiKey: 'AIzaSyCa23KNpNDntqcNgh7bLFcJ9ElLjxr5nmE', //chiave di PRAGMATICA
      apiKey: 'AIzaSyBE0usBqGcqapWrhiyKn5DcD853fxM9e74', //chiave PYXIE
      libraries: ['places']
    }),
    AgmMarkerClustererModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    NgxStripeModule.forRoot(environment.payments.stripe_pk_test),
  ],
  exports: [
    NgxStripeModule
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 2000,
        verticalPosition: 'top',
        panelClass: ['pyxie-snackbar'],
      },
    },
    {
      provide: AmplifyService,
      useFactory: () => {
        return AmplifyModules({
          Auth,
        });
      },
    },
    FirebaseService,
    CookieService,
    MatDialog,
    MatDatepickerModule,  
    {
      provide: LOCALE_ID,
      useFactory: () => {
        const browserLang = window.navigator.language.slice(0, 2);

        return browserLang.match(/en|it/) ? browserLang : 'en';
      },
    },
  ],
  schemas: [NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
