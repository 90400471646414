import { Component, OnInit } from '@angular/core';
import Amplify from 'aws-amplify';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../environments/environment';
import { FirebaseService } from './core/services/firebase.service';
import { CookieService } from 'ngx-cookie-service';
import firebase from 'firebase';
import 'firebase/messaging';
import { EmitterService } from './core/services/emitter.service';
import { ToastrService } from 'ngx-toastr';
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  message;
  cookieValue;
  constructor(private translateService: TranslateService,
    private emitterService: EmitterService, private messagingService: FirebaseService, private cookieService: CookieService,
    private toastrService: ToastrService) {
    // config amplify
    Amplify.configure(environment.amplify.config);


    //config ngx-translate
    translateService.setDefaultLang('en');
    translateService.addLangs(["it"]);

    let browserLang: string = translateService.getBrowserLang();
    translateService.use(browserLang.match(/it/) ? browserLang : translateService.getDefaultLang());


    this.cookieValue = this.cookieService.get('TokenFirebase');
    firebase.initializeApp({
      apiKey: environment.firebaseConfig.apiKey,
      authDomain: environment.firebaseConfig.authDomain,
      databaseURL: environment.firebaseConfig.databaseURL,
      projectId: environment.firebaseConfig.projectId,
      storageBucket: environment.firebaseConfig.storageBucket,
      messagingSenderId: environment.firebaseConfig.messagingSenderId,
      appId: environment.firebaseConfig.appId,
      measurementId: environment.firebaseConfig.measurementId
    });
    if (firebase.messaging.isSupported()) {
      // console.log("SUPPORTATO")
      const messagge = firebase.messaging();
      this.emitterService.firebaseSupported.emit(true);
      this.emitterService.firebaseSupportedVariable = true;
      this.messagingService.requestPermission().then(res => {
        if (this.cookieValue != res) {
          this.cookieService.set('TokenFirebase', res);
          // console.log("SI È DIVERSO")
        }
        this.message = this.messagingService.currentMessage;
      });
      this.messagingService.receiveMessage();
    } else {
      // console.log("NON SUPPORTATO")
      this.emitterService.firebaseSupportedVariable = false;
      this.emitterService.firebaseSupported.emit(false);
    }
  }

  ngOnInit() {

  }
}
