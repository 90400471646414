import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { AmplifyService } from 'aws-amplify-angular';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, bindCallback } from 'rxjs';

import { EmitterService } from './emitter.service';

@Injectable()
export class FirebaseService {
    token: any;
    currentMessage = new BehaviorSubject(null);

    constructor(private angularFireMessaging: AngularFireMessaging, public emitterService: EmitterService,
        public dialog: MatDialog, public toastrService: ToastrService, public router: Router, private amplifyService: AmplifyService,
        public route: ActivatedRoute) {
        this.angularFireMessaging.messages.subscribe(
            (_messaging: any) => {
                _messaging.onMessage = _messaging.onMessage.bind(_messaging);
                _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
            }
        )
    }
    async requestPermission(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.angularFireMessaging.requestToken.subscribe(
                (token) => {
                    resolve(token);
                },
                (err) => {
                    reject(err);
                    console.error('Unable to get permission to notify.', err);
                }
            );
        })
    }

    receiveMessage() {

        this.angularFireMessaging.messages.subscribe(
            (payload: any) => {
                // console.log("new message received. ", payload);
                Auth.currentAuthenticatedUser({ bypassCache: true })  //usare questa funzione e non currentUserInfo()
                    .then(cognitoUser => {
                        this.currentMessage.next(payload);

                        if (payload.notification) {   // GESTIONE NOTIFICA PER EXPORT E EDITING
                            let path = '/events/' + payload.data.eventId + '/exports;type=' + payload.data.eventType;
                            if (window.location.pathname == path) {
                                if (payload.data.action == 'autoediting') {
                                    sessionStorage.setItem('montaggio', 'true');
                                    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                                        this.router.navigate(['/events/' + payload.data.eventId + '/exports', { type: payload.data.eventType }])
                                    });

                                } else {
                                    sessionStorage.setItem('montaggio', 'false');
                                    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                                        this.router.navigate(['/events/' + payload.data.eventId + '/exports', { type: payload.data.eventType }])
                                    });
                                }
                            } else {
                                this.toastrService.info(payload.notification.body, payload.notification.title, { tapToDismiss: true, disableTimeOut: true, closeButton: true }).onTap.subscribe((action) => {
                                    if (payload.data.action == 'exports') {
                                        sessionStorage.setItem('montaggio', 'false');
                                        this.router.navigateByUrl('/', { skipLocationChange: false }).then(() => {
                                            this.router.navigate(['/events/' + payload.data.eventId + '/exports', { type: payload.data.eventType }])
                                        });

                                    } else if (payload.data.action == 'autoediting') {

                                        sessionStorage.setItem('montaggio', 'true');
                                        this.router.navigateByUrl('/', { skipLocationChange: false }).then(() => {
                                            this.router.navigate(['/events/' + payload.data.eventId + '/exports', { type: payload.data.eventType }])
                                        });

                                    }
                                })
                            }
                        } else {  // GESTIONE NOTIFICA PER PROGRESS BAR VIDEOCLIP
                            let path = '';
                            let masterPath = '';
                            let eventType = '';
                            if (window.location.pathname.includes('offline')) {
                                path = '/events/' + payload.data.eventId + '/video;type=offline';
                                masterPath = payload.data.masterEventId !== undefined ? '/events/' + payload.data.masterEventId + '/video;type=offline' : '';
                                eventType = 'offline';
                            } else if (window.location.pathname.includes('live')) {
                                path = '/events/' + payload.data.eventId + '/video;type=live';
                                masterPath = payload.data.masterEventId !== undefined ? '/events/' + payload.data.masterEventId + '/video;type=live' : '';
                                eventType = 'live';
                            }

                            if (window.location.pathname == path) {
                                if (payload.data.status == 'IN_PROGRESS') {
                                    let obj = {
                                        status: 'IN_PROGRESS',
                                        clipId: payload.data.clipId,
                                        percentage: payload.data.percentage
                                    }
                                    this.emitterService.inProgress.emit(obj);

                                    // this.router.navigateByUrl('/', { skipLocationChange: false }).then(() => {
                                    //     this.router.navigateByUrl('/events/' + payload.data.eventId + '/video;type=' + eventType, { state: {clipId: payload.data.clipId, notifyPercentage: payload.data.percentage,  } });
                                    // }); 
                                } else if (payload.data.status == 'COMPLETED') {
                                    let obj = {
                                        status: 'COMPLETED',
                                        clipId: payload.data.clipId
                                    }
                                    this.emitterService.inProgress.emit(obj);
                                } else if (payload.data.status == 'NEW_IN_PROGRESS' || payload.data.status == 'REJECTED') {
                                    let obj = {
                                        status: 'ALL',
                                        clipId: payload.data.clipId,
                                    }
                                    this.emitterService.inProgress.emit(obj);
                                }

                            }

                            if (masterPath !== '') {
                                if (window.location.pathname == masterPath) {
                                    if (payload.data.status == 'IN_PROGRESS') {
                                        let obj = {
                                            status: 'IN_PROGRESS',
                                            clipId: payload.data.clipId,
                                            percentage: payload.data.percentage
                                        }
                                        this.emitterService.inProgress.emit(obj);

                                        // this.router.navigateByUrl('/', { skipLocationChange: false }).then(() => {
                                        //     this.router.navigateByUrl('/events/' + payload.data.eventId + '/video;type=' + eventType, { state: {clipId: payload.data.clipId, notifyPercentage: payload.data.percentage,  } });
                                        // }); 
                                    } else if (payload.data.status == 'COMPLETED') {
                                        let obj = {
                                            status: 'COMPLETED',
                                            clipId: payload.data.clipId
                                        }
                                        this.emitterService.inProgress.emit(obj);
                                    } else if (payload.data.status == 'NEW_IN_PROGRESS' || payload.data.status == 'REJECTED') {
                                        let obj = {
                                            status: 'ALL',
                                            clipId: payload.data.clipId,
                                        }
                                        this.emitterService.inProgress.emit(obj);
                                    }

                                }
                            }

                        }
                    }).catch((err) => {
                        console.log("ERRORE", err);
                    });
            })
    }

}