import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-redirect-mosaic',
  templateUrl: './redirect-mosaic.component.html',
  styleUrls: ['./redirect-mosaic.component.scss']
})
export class RedirectMosaicComponent implements OnInit {

  constructor(
    private route: ActivatedRoute
  ) { }

  coupon: string;

  ngOnInit(): void {
    
    this.coupon = this.route.snapshot.queryParams["COUPON"];

  }

  openMosaic(){
    const href = document.createElement("a");
    href.href = `mosaic://open?COUPON=${this.coupon}`
    href.target = "_blank";
    document.body.appendChild(href);
    href.click();
  }


}
