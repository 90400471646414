import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { RedirectMosaicComponent } from './redirect-mosaic/redirect-mosaic.component';

// ----------------------------------------------------------------------------

const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  {
    path: 'mosaic',
    component: RedirectMosaicComponent
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./logged-app/logged-app.module').then((m) => m.LoggedAppModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
