import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class EmitterService {
    emmiter = new EventEmitter();
    eventDetail = new EventEmitter();
    tabInfo = new EventEmitter();
    tabVideo = new EventEmitter();
    tabUsers = new EventEmitter();
    tabExports = new EventEmitter();
    tabRelated = new EventEmitter();
    eventType = new EventEmitter();
    masterEvent = new EventEmitter();
    profileImage = new EventEmitter();
    enableRelatedEvents = new EventEmitter();

    inProgress = new EventEmitter();
    firebaseSupported = new EventEmitter();


    firebaseSupportedVariable: boolean;
}