export const environment = {
  production: false,
  apiBaseURL: 'https://api-lab.pyxie.live/api',
  cloudfrontAsset: "https://lab.pyxie.live",
  amplify: {
    config: {
      Auth: {
        region: 'eu-central-1',
        userPoolId: 'eu-central-1_llFKQKfLr',
        userPoolWebClientId: '2qrr3mki6bo4keailrv4fgg9ei',
        oauth: {
          domain: 'pyxie-users-lab.auth.eu-central-1.amazoncognito.com',
          scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: 'https://lab.pyxie.live/auth/sign-in',
          redirectSignOut: 'https://lab.pyxie.live/auth/sign-in',
          responseType: 'code',
        },
        federationTarget: 'COGNITO_USER_POOLS',
        cookieStorage: {
          // domain: 'URL_PROD',
          domain: 'lab.pyxie.live',
          path: '/',
          expires: 30,
          secure: false,
        },
      },
      Analytics: {
        disabled: true,
      },
    },
    logLevel: 'ERROR',
  },
  bodyFirebase:{
    dynamicLinkInfo: {
      domainUriPrefix: "https://pyxiedev.page.link",
      link: 'https://api-lab.pyxie.live/api/events/$COUPON_CODE/join-me',
      androidInfo: {
        androidPackageName: "com.pyxie.app"
      },
      iosInfo: {
        iosBundleId: "com.pyxie",
        iosAppStoreId: "1468072658"
      }
    }
  },
  firebaseConfig:{
    apiKey: "AIzaSyBG8MHefoAo8ke3Cgf0amoBs1ibte9yXuA",
    authDomain: "pyxie-dev.firebaseapp.com",
    databaseURL: "https://pyxie-dev.firebaseio.com",
    projectId: "pyxie-dev",
    storageBucket: "pyxie-dev.appspot.com",
    messagingSenderId: "1081690322928",
    appId: "1:1081690322928:web:29bb82b61a361e58598f35",
    measurementId: "G-VT32G9D6VX"
  },
  payments:{
    stripe_pk_test: 'pk_test_51IVuASEmPHn2xKJHqvfRZhFeKRqOG8M8yLccpvi8bmZgEOjO2CueA4AzZlxDQd57oUOeN2JyQwMCc4pkrN4q4Th400VZ3VMtG3'
  }
};
